import('./bootstrap').catch((err) => console.error(err));
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      
    })
    .catch((err) => {
      console.error('Service Worker registration failed:', err);
    });
}
